<script setup lang="ts">
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useUserAuthStore } from '~~/src/services/userAuth';

import Tracker from '~/libs/Tracker';
import { now, safeDateParse } from '~/libs/dateUtils';

import Utility from '~/services/Utility';
import { useCouponStore } from '~/services/coupon/store';
import { useMainStore } from '~/services/main';
import { type DesignPopup } from '~/services/main/type';
import { PRODUCT_DETAIL_TARGETS } from '~/services/productV2/constants';
import { useUserProfileStore } from '~/services/profile/store';
import { useInterestStore } from '~/services/users/interesttags/store';

import { IconX } from '~/components/5x';

const route = useRoute();
const runtime = useRuntimeConfig();
const couponStore = useCouponStore();
const mainStore = useMainStore();
const interestStore = useInterestStore();
const userAuthStore = useUserAuthStore();
const userProfileStore = useUserProfileStore();
//TODO :: 사전 예약 팝업 으로 기간이 지나면 추후 삭제 한다.
const limit1 = safeDateParse('2024-11-28T11:00:00');
const limit2 = safeDateParse('2024-11-28T23:59:59');

const preReservationDisplay = ref({
  designPopupSeq: 0,
  designPopupTitle: '사전예약',
  designPopupViewArea: 'M',
  // designPopupPcImageUrl: getCdn(`/fe/product/3618/benefit_popup_3x.webp`),
  designPopupPcImageUrl: '/images/product/benefit_popup_3x.webp',
  designPopupPcImageOrgName: 'benefit_popup_3x.webp',
  designPopupMobileImageUrl: '/images/product/benefit_popup_3x.webp',
  // designPopupMobileImageUrl: getCdn(`/fe/product/3618/benefit_popup_3x.webp`),
  designPopupMobileImageOrgName: 'benefit_popup_3x.webp',
  designPopupFrameType: 'B',
  designPopupLinkType: 'P',
  designPopupLinkUrl: 'https://weolbu.com/product/3618',
  designPopupStartDate: '2024-11-28T09:00:00',
  designPopupEndDate: '2024-11-28T23:59:59',
  designPopupWidthSize: 400,
  designPopupHeightSize: 400,
  designPopUpCategoryList: null,
  ui_visible: true,
  ui_is_open_oneday: false,
});

// 상태 객체 생성
const announcementDialogState = reactive({
  isOpen: false, // 공지사항 Dialog 열림 여부
  isDismissed: false, // 사용자가 닫기 이벤트를 실행했는지 여부
});

const isProductPreReservation = computed(() => {
  if (!['index', 'community', 'class'].includes(route.name)) {
    return false;
  }

  if (
    !(
      userProfileStore.profile?.preReservationDisplayIds &&
      userProfileStore.profile?.preReservationDisplayIds.length > 0 &&
      userProfileStore.profile?.preReservationDisplayIds.find((seq) => PRODUCT_DETAIL_TARGETS.includes(seq))
    ) // 3618
  ) {
    return false;
  }
  // QA 체크를 위해 추가
  if (route.query.popupTime && runtime.public.app_env !== 'prod') {
    return (
      safeDateParse(route.query.popupTime).isSame(limit1) ||
      safeDateParse(route.query.popupTime).isSame(limit2) ||
      (safeDateParse(route.query.popupTime).isAfter(limit1) && safeDateParse(route.query.popupTime).isBefore(limit2))
    );
  }
  return now().isSame(limit1) || now().isSame(limit2) || (now().isAfter(limit1) && now().isBefore(limit2));
});

const isVisibleException = computed(() => (item: DesignPopup) => {
  /** 웰컴 팝업이 등장 할때, 메인 팝업은 동작하지 않는다. */
  if (couponStore.welcomePopupVisible) {
    return true;
  }

  /** 관심사 팝업이 등장 할때, 메인 팝업은 동작하지 않는다. */
  if (interestStore.interestPopupVisible) {
    return true;
  }

  /** 첫구매 컨텐츠 관련 */
  const isPopupForZero = item.designPopupLinkUrl.includes('/zero');
  const isZeroUser = isEmpty(userAuthStore.user) || userAuthStore.user?.paymentFunnel === 'ZERO';
  if (isPopupForZero && !isZeroUser) {
    return true; //=> 첫구매 관련 팝업 컨텐츠는 : Zero,none 유저에게만 노출한다.
  }

  return false;
});

const currentPagePopupList = computed(() => {
  // TODO :: 사전 예약 팝업 으로 기간이 지나면 추후 삭제 한다.
  if (isProductPreReservation.value) {
    return [preReservationDisplay.value];
  }

  if (route.name === 'index') {
    // main
    return mainStore.mainDesignPupupList;
  } else if (route.name === 'community') {
    // community
    return mainStore.boardDesignPupupList;
  } else if (route.name === 'class') {
    // category
    const myPopupCategoryList = mainStore.categoryDesignPupupList.filter((v) =>
      v.designPopUpCategoryList!.some((vv) => vv.cateSeq == (route.query.cateSeq as any)),
    );

    return myPopupCategoryList;
  }
});

watch(
  () => userAuthStore.user,
  (user) => {
    if (user) {
      mainStore.fetchDesignPopup();
    }
  },
  { immediate: true },
);

function onPopupOpenOneDay(item: DesignPopup) {
  nextTick(() => {
    item.ui_is_open_oneday = !item.ui_is_open_oneday;
    if (item.ui_is_open_oneday) {
      Utility.cacheLastOpenSeq({
        seq: item.designPopupSeq,
        type: 'designPopupSeq',
      });
    } else {
      Utility.removeCacheLastOpenSeq({
        seq: item.designPopupSeq,
        type: 'designPopupSeq',
      });
    }
    item.ui_visible = false;
  });
}

const movePage = (item: DesignPopup) => {
  const {
    public: { frontUrl },
  } = useRuntimeConfig();
  const prevRoute = sessionStorage.getItem('prevRoute');
  const prevPath = sessionStorage.getItem('prevPath');
  Tracker['Click Popup']({
    location: route.name === 'index' ? 'MAIN' : route.name === 'community' ? 'COMMUNITY' : 'CATEGORY',
    popupTitle: currentPagePopupList.value?.[0]?.designPopupTitle,
    popupUrl: currentPagePopupList.value?.[0]?.designPopupLinkUrl,
    path: prevRoute,
    url: `${frontUrl}${prevPath}`,
  });
  Utility.openLink(item.designPopupLinkUrl, item.designPopupLinkType);
};

// 메인 팝업의 모든 큐가 빈 경우, 메인팝업이 모두 닫히는 것으로 간주
watch(
  () => mainStore.getMainPopupQueue,
  (popupQueue) => {
    mainStore.setMainPopupVisible(popupQueue.length > 0);
  },
  {
    immediate: true,
  },
);
const visibleUpdated = (popupItem, isHide) => {
  const currentMainPopupQueue = mainStore.getMainPopupQueue;
  if (isHide) {
    // 닫힘
    mainStore.setMainPopupQueue(currentMainPopupQueue.filter((v) => v.designPopupSeq !== popupItem.designPopupSeq));
  } else {
    // 열림
    mainStore.setMainPopupQueue([...currentMainPopupQueue, popupItem]);
  }
};

// [START] 개보위 관련 공지사항 관련 코드, 임시로 추가되었으므로 리액트 마이그레이션 필요 없음
const handleAnnouncementDialogClose = () => {
  announcementDialogState.isOpen = false;
  announcementDialogState.isDismissed = true;
  localStorage.setItem('wb:announcementDialogClosed', 'true');
};

const handleAnnouncementDialogOutsideClick = (event: Event) => {
  const clickedElement = event.target as HTMLElement;
  const isDialog = clickedElement.closest('.announcement-dialog');

  if (!isDialog && announcementDialogState.isOpen) {
    handleAnnouncementDialogClose();
  }
};

watch(
  () => route.path,
  () => {
    const isAnnouncementDialogClosed = localStorage.getItem('wb:announcementDialogClosed') === 'true';

    if (!isAnnouncementDialogClosed) {
      const isMainPage = route.path === '/';
      const start = dayjs('2024-12-12T10:00:00');
      const end = dayjs('2024-12-14T09:59:59');
      const now = dayjs();

      if (isMainPage && now.isAfter(start) && now.isBefore(end)) {
        announcementDialogState.isOpen = true;
        localStorage.setItem('wb:announcementDialogClosed', 'true');
      }
      return;
    }

    // 페이지 이동시에 자동으로 꺼지도록
    announcementDialogState.isOpen = false;
  },
  { immediate: true },
);

onMounted(() => {
  document.addEventListener('click', handleAnnouncementDialogOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleAnnouncementDialogOutsideClick);
});
// [END] 개보위 관련 공지사항 팝업 코드, 임시로 추가되었으므로 리액트 마이그레이션 필요 없음
</script>

<template>
  <div>
    <!-- [START] 개보위 관련 공지사항 관련 코드, 임시로 추가되었으므로 리액트 마이그레이션 필요 없음 -->
    <Dialog
      v-if="announcementDialogState.isOpen"
      :visible="announcementDialogState.isOpen"
      header="공지사항"
      :modal="false"
      :closable="false"
      :draggable="false"
      :dismissable-mask="true"
      :base-z-index="2000"
      :auto-z-index="true"
      class="announcement-dialog">
      <template #header>
        <div class="flex justify-end w-full cursor-pointer">
          <IconX class="text-neutral-700" thickness="extra-light" @click="handleAnnouncementDialogClose" />
        </div>
      </template>
      <div>
        <p class="text-sm">
          안녕하세요. 회원 여러분, 지난해 월급쟁이부자들은 보다 안정적인 월부닷컴을 제공하기 위해 위탁 외주업체와 새로운
          월부닷컴을 개발하였습니다. 안타깝게도 개발 과정에서 위탁 업체의 실수로 보안상 취약점이 있었고, 2023.9.27 해당
          취약점을 악용한 해킹 사고가 있었습니다.
          <br /><br />
          따라서 <b>월급쟁이부자들(주)</b>은 <b>개인정보 보호법 위반</b>을 이유로 처분 등을 받은 사실을 안내드립니다.
          <br /><br />
          개발사의 실수로 인한 해킹 사고 였지만 <b>월급쟁이부자들(주)</b>는 23.7.21 ~ 23.10.12 약 2개월 동안
          <b>개인정보처리시스템에 대한 접근통제, 접근권한 조치 등 안전조치의무를 소홀</b>했다는 점에
          <b>개인정보 보호법 위반</b>을 이유로 <b>개인정보보호위원회</b>로부터 과징금, 과태료를 처분 받았습니다.
          <br /><br />
          사고 즉시 개인정보 접근 제어 강화, 네트워크 망분리 등 전 분야 보안 조치를 강화하였고, 한국인터넷진흥원의
          점검과 자문을 구하여 권고사항까지 선제 조치하여 안정한 정보 보안 시스템을 구축하였습니다.
          <br /><br />
          또한 2023.09.28 이후 월부닷컴에 가입한 회원분들께서는 본 사고에 해당하지 않음을 알려드립니다.
          <br /><br />
          월급쟁이부자들을 믿고 이용해주시는 많은 회원분들께 심려 끼쳐 드린 점 죄송합니다. 처분을 성실히 이행하고,
          안전한 월부닷컴을 위해 모든 직원이 최선을 다해 노력하겠습니다. 관련 문의가 있다면 1:1문의에 남겨주시면
          순차적으로 답변드리겠습니다.
        </p>
        <div class="mt-4 text-sm text-right font-semibold">
          <p>2024년 12월 12일</p>
          <p>월급쟁이부자들(주)</p>
          <p>대표 이정환</p>
        </div>
      </div>
      <template #footer> </template>
    </Dialog>
    <!-- [END 개보위 관련 공지사항 관련 코드, 임시로 추가되었으므로 리액트 마이그레이션 필요 없음 -->
    <Dialog
      v-else-if="!announcementDialogState.isDismissed"
      v-for="(item, index) in currentPagePopupList"
      :key="`${item}_${index}`"
      :visible="item.ui_visible && !isVisibleException(item as DesignPopup)"
      :modal="item.designPopupFrameType == 'L'"
      class="wb-dialog-main"
      :style="{
        width: `${item.designPopupWidthSize}px`,
        'min-height': '150px',
      }"
      @hide="visibleUpdated(item, true)"
      @show="visibleUpdated(item, false)">
      <div class="wb-dialog-main__contents">
        <img
          :src="
            isMobile
              ? Utility.getOptimized(item.designPopupMobileImageUrl, { q: 80 })
              : Utility.getOptimized(item.designPopupPcImageUrl, { q: 80 })
          "
          alt=""
          loading="lazy"
          @click="movePage(item)" />
      </div>
      <div class="wb-dialog-main__foot">
        <p @click="onPopupOpenOneDay(item)">
          <label :for="`todayClose_${index}`">오늘 하루 보지 않기</label>
        </p>
        <Button icon="pi pi-times" severity="secondary" text rounded @click="item.ui_visible = false">
          <span class="p-button-icon"> </span>
        </Button>
      </div>
    </Dialog>
  </div>
</template>

<style lang="scss">
.wb-dialog-main {
  min-width: 320px;
  max-width: 1080px;
  overflow: hidden;
  border: 1px solid rgba(9, 30, 66, 0.05);
  border-radius: 8px;
  @media screen and (max-width: $md) {
    max-width: calc(100% - 32px);
  }
  .p-dialog-content {
    padding: 0;
  }
  &__contents {
    cursor: pointer;
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-top: 0px;
    p {
      display: flex;
      align-items: center;
      cursor: pointer;

      .p-checkbox {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }

      label {
        margin: 10.5px 12px;
        color: #788194;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: $md) {
          margin: 11.5px 12px;
        }
      }
    }

    button:enabled {
      .p-button-icon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: url('/icon/click-area.svg') no-repeat 50% 50%;
      }
      &:hover {
        border-radius: 0px;
        background: unset !important;
      }
    }
  }
}

.announcement-dialog {
  max-width: 500px;
  min-width: 320px;
  height: 50dvh;
  min-height: 320px;
  margin: 0 16px !important;
  border-radius: 8px;

  .p-dialog-header {
    display: flex;
    padding: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    justify-content: flex-end;
  }
  .p-dialog-content {
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0;
    height: auto;

    &::-webkit-scrollbar-thumb {
      display: block !important;
    }
  }
  .p-dialog-footer {
    padding: 0px 16px 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
